
type Configuration = {
    title: string
    auth_server_url: string,
    session_server_url: string,
}

const configuration: Configuration = (process.env.NODE_ENV === 'development') ?
    {
        title: 'SmartDAS debug',
        auth_server_url: 'http://localhost:3001/auth',
        session_server_url: 'http://localhost:3003',
    }
    : 
    {
        title: 'SmartDAS',
        auth_server_url: 'https://smartdas-server.smartport.one:3001/auth',
        session_server_url: 'https://smartdas-server.smartport.one:3003',
    };

export default configuration;
