import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import DockingWidget from './DockingWidget';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  }
});
// grid layout end

function Docking() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            Docking
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DockingWidget replayMode={false} />
        </Grid>
      </Grid>
      {/* <WindSensorWidget client={mqttContext.client} northRotation={66} windSpeedWarningThreshold={20} windSpeedAlertThreshold={30} gustWarningThreshold={40} gustAlertThreshold={50} /> */}
    </div>
  );
}

export default Docking;
