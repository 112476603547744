import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import DownloadSession from './DownloadSession';

const Sessions = () => {
  return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              Sessions
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DownloadSession />
          </Grid>
        </Grid>
      </div>
  );
};

export default Sessions;
