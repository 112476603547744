import React, { useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { Button, makeStyles } from '@material-ui/core';
import { User, useSettings } from './App';
import mqttClient, { ConnectFunc } from './MqttClient';
import MQTTPattern from 'mqtt-pattern';
import { AlarmData, AlarmState } from './AlarmManager';

const useStyles = makeStyles((theme) => ({
  alarmSnackbar: {
    color: theme.palette.primary.contrastText,
  },
}));

let alarmsAuditorySetting = false;
let alarmsSpokenSetting = false;
let alarmsPopupSetting = false;
let alarmsBrowserSetting = false;
let lastRecievedData = new Date();

const timeoutWarningSeconds = 30;
let timeoutSnackbarKey: string | number | undefined;
let offlineSnackbarKey: string | number | undefined;
let lastUser: User | null = null;
let connectHandle: ConnectFunc | null;

const Alarms = () => {
  const classes = useStyles();
  let audioPreventedSnackbarKey: string | number | undefined;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    connectHandle = mqttClient.onConnect(() => {
      mqttClient.subscribe(`${mqttClient.lmsTopic}/alarms`);
      mqttClient.listen('site/+/lms/+/#', (topic, data) => {
        lastRecievedData = new Date();
        if (!data) return;
        if (MQTTPattern.matches(`${mqttClient.lmsTopic}/alarms`, topic)) {
          handleAlarms(data.alarms);
        }
      });
    });
    
    return function cleanup() {
      if (connectHandle) mqttClient.removeOnConnect(connectHandle);
      mqttClient.unsubscribe(`${mqttClient.lmsTopic}/alarms`);
      mqttClient.unlisten('site/+/lms/+/#');
    };    
  });

  function playSound(alert: boolean) {
    const promise = new Audio(`audio/${alert ? 'alert' : 'warning'}.mp3`).play();
    if (promise) {
      promise
        .then((_) => {
          if (audioPreventedSnackbarKey) {
            closeSnackbar(audioPreventedSnackbarKey);
            audioPreventedSnackbarKey = undefined;
          }
        })
        .catch((_error) => {
          audioPreventedSnackbarKey = enqueueSnackbar(<div><b>Audio was prevented!</b><br/>Click on the page to enable audio.</div>, { 
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            persist: true,
            variant: 'warning',
            key: 'autoplay-prevented',
          }).valueOf();
        });
    }
  }

  function notification(text: string) {
    function notify() {
      // var notification = 
      new Notification(text);
    }

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
      return;
    } else if (Notification.permission === 'granted') { // Check if notification permissions have already been granted
      notify();
    } else if (Notification.permission !== 'denied') { // Otherwise, we need to ask the user for permission
      Promise.resolve(Notification.requestPermission()).then(function (permission) {
        if (permission === 'granted') { // If the user accepts, let's create a notification
          notify();
        }
      });
    }
  }

  function speak(list: string[]) {
    if (list.length) {
      const msg = new SpeechSynthesisUtterance();
      msg.text = list[0];

      speechSynthesis.speak(msg);

      msg.addEventListener('end', function(_event) {
        window.setTimeout(() => {
            speak(list.slice(1));
        }, 100);
      });
    }
  }

  const { alarmsAuditory, alarmsSpoken, alarmsPopup, alarmsBrowser } = useSettings();

  useEffect(() => {
    alarmsAuditorySetting = alarmsAuditory;
    alarmsSpokenSetting = alarmsSpoken;
    alarmsPopupSetting = alarmsPopup;
    alarmsBrowserSetting = alarmsBrowser;
  });

  function handleAlarms(alarms: AlarmData[]) {
    if (alarms.length === 0) return;
    alarms = alarms.sort(); // sort to have alarms first, then warnings
    if (alarmsAuditorySetting) {
      const hasAlert = !!alarms.find((a) => a.state === AlarmState.ALERT);
      playSound(hasAlert);
    }
    
    if (alarmsSpokenSetting) {
      speak(alarms.map(m => m.message));
    }
    
    alarms.forEach(handleAlarmData);
  }

  function handleAlarmData(alarm: AlarmData) {
    if (alarmsPopupSetting) {
      let variant: VariantType = 'info';
      switch (alarm.state) {
        case AlarmState.WARNING: variant = 'warning'; break;
        case AlarmState.ALERT:   variant = 'error';   break;
      }

      const action = (key: string | number) => (
        <Button className={classes.alarmSnackbar} onClick={(_) => closeSnackbar(key)}>
            Dismiss
        </Button>
      );
      
      enqueueSnackbar(alarm.message, { 
        persist: true,
        variant: variant,
        action,
      });
    }
    
    if (alarmsBrowserSetting) {
      notification(alarm.message);
    }
  }

  const { user } = useSettings();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!user) return;

      const userJustLoggedIn = (user !== lastUser);
      if (userJustLoggedIn) {
        lastUser = user;
        lastRecievedData = new Date();
      }
      const timeDiffSeconds = ((new Date()).getTime() - lastRecievedData.getTime()) / 1000;
      if (timeDiffSeconds > timeoutWarningSeconds) {
        timeoutSnackbarKey = enqueueSnackbar(<div><b>No connection to LMS</b><br/>Reestablish connection to get live data.</div>, { 
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          persist: true,
          variant: 'error',
          key: 'no-connection-to-lms',
        }).valueOf();
      } else if (timeoutSnackbarKey) {
        closeSnackbar(timeoutSnackbarKey);
        timeoutSnackbarKey = undefined;
  
        enqueueSnackbar('Connection to LMS reestablished.', { 
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          variant: 'success',
          autoHideDuration: 5000,
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  window.addEventListener('load', function() {
    function updateOnlineStatus() {
      if (!navigator.onLine) {
        offlineSnackbarKey = enqueueSnackbar(<div><b>No internet connection</b><br/>Reestablish connection to get live data.</div>, { 
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          persist: true,
          variant: 'error',
          key: 'no-connection',
        }).valueOf();
      } else if (offlineSnackbarKey) {
        closeSnackbar(offlineSnackbarKey);
        offlineSnackbarKey = undefined;

        enqueueSnackbar('Connection reestablished.', { 
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          variant: 'success',
          autoHideDuration: 5000,
        });
      }
    }
  
    window.addEventListener('online',  updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  });

  return (
    null
  );
};

export default Alarms;
