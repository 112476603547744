import React from 'react';
import { Card, CardContent, TableContainer, Table, TableHead, Paper, TableRow, TableCell, TableBody, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, Brush, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { AlarmState } from './AlarmManager';
import WarningZones, { WarningZone, AlarmMode } from './WarningZones';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import mqttClient from './MqttClient';
import configuration from './Configuration';
const MQTTPattern = require('mqtt-pattern');

type DownloadSessionProp = {};
type GraphData = {
  timestamp: number,
  distance_left: number,
  distance_right: number,
  speed_left: number,
  speed_right: number,
  angle: number,
  warning_zone: WarningZone | null,
};
type AlarmData = {
  timestamp: number,
  message: string,
  state: AlarmState,
};
type SessionData = {
  vessel_name: string,
  start_time: Date,
  end_time: Date,
};
type DownloadSessionState = {
  graph_data: GraphData[],
  alarm_data: AlarmData[],
  session: number,
  sessions: SessionData[],
};

const tickFormatter = (tick: Date) => moment(tick).format('HH:mm:ss');
const labelFormatter = (timestamp: React.ReactText) => moment(timestamp).format('HH:mm:ss D MMMM YYYY');
const sessionServerUrl = configuration.session_server_url;
class DownloadSession extends React.Component<DownloadSessionProp, DownloadSessionState> {
  constructor(props: DownloadSessionProp) {
    super(props);
    this.state = {
      graph_data: [],
      alarm_data: [],
      session: -1,
      sessions: [],
    };

    fetch(`${sessionServerUrl}/sessions/${mqttClient.siteId}/${mqttClient.lmsId}`,{
        headers: new Headers(),
        mode: 'cors',
        cache: 'default',
      })
      .then(response => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        this.setState({
          sessions: jsonData.map((e: any): SessionData =>  {
            return {
              vessel_name: e.vesselName,
              start_time: new Date(e.startTime),
              end_time: new Date(e.endTime),
            };
          })
        });
      })
      .catch((_error) => {
        // handle your errors here
        console.log('Could not fetch session data.');
        // console.error(error);
      });
  }

  sessionSelected(session: SessionData) {
    fetch(`${sessionServerUrl}/session/${session.start_time.getTime()}/${session.end_time.getTime()}`)
      .then(response => response.json())
      .then((jsonData) => {
        let warningZones: WarningZone[] = [];

        // jsonData is parsed json object received from url
        const graph_data: GraphData[] = jsonData
        .map((d: any): GraphData | null => {
          if (MQTTPattern.matches(`${mqttClient.lmsTopic}/+/Terminal zones`, d.topic)) {
            const data = JSON.parse(d.message);
            warningZones = WarningZones.fromJSON(data);
            return null;
          }
          if (!MQTTPattern.matches(`${mqttClient.lmsTopic}/+/TerminalData`, d.topic)) return null;

          const data = JSON.parse(d.message);
          return {
            timestamp: new Date(d.timestamp).getTime(),
            distance_left: data.DistanceLeft.toFixed(2),
            distance_right: data.DistanceRight.toFixed(2),
            speed_left: data.SpeedLeft.toFixed(2),
            speed_right: data.SpeedRight.toFixed(2),
            angle: data.Angle.toFixed(2),
            warning_zone: WarningZones.getWarningZone(warningZones, Math.max(data.DistanceLeft, data.DistanceRight)),
          };
        }).filter((d: GraphData | null) => d);

        const alarm_data: AlarmData[] = jsonData
          .map((d: any): AlarmData | null => {
            if (!MQTTPattern.matches(`${mqttClient.lmsTopic}/alarms`, d.topic)) return null;
            const data = JSON.parse(d.message);
            if (!data || !data.alarms) return null;
            return data.alarms.map((v: any) => {
              return {
                timestamp: new Date(d.timestamp).getTime(),
                message: v.message,
                state: v.state,
              };
            });
          })
          .filter((d: AlarmData | null) => d)
          .flat();

        this.setState({
          graph_data: graph_data,
          alarm_data: alarm_data,
        });
      })
      .catch((error) => {
        // handle your errors here
        console.error(error);
      });
  }

  getSessionDownloadLink(session: SessionData) {
    return `${sessionServerUrl}/session/data/csv/${session.start_time.getTime()}/${session.end_time.getTime()}`;
  }
  
  getSessionAlarmsDownloadLink(session: SessionData) {
    return `${sessionServerUrl}/session/alarms/csv/${session.start_time.getTime()}/${session.end_time.getTime()}`;
  }

  getAlarmColor(value: number, warningLimit: number | undefined, alertLimit: number | undefined): string {
    switch (WarningZones.getAlarmMode(value, warningLimit, alertLimit)) {
      case AlarmMode.UNKNOWN: return '#777';
      case AlarmMode.NO_ALARM: return 'black';
      case AlarmMode.WARNING: return 'orange';
      case AlarmMode.ALERT: return 'red';
    }
  }
  
  getAlarmIcon(value: number, warningLimit: number | undefined, alertLimit: number | undefined): React.ReactNode {
    switch (WarningZones.getAlarmMode(value, warningLimit, alertLimit)) {
      case AlarmMode.UNKNOWN: return <React.Fragment />;
      case AlarmMode.NO_ALARM: return <React.Fragment />;
      case AlarmMode.WARNING: return <WarningIcon />;
      case AlarmMode.ALERT: return <ErrorIcon />;
    }
  }

  render() {
    return (
      <div>
        <Card>
          <CardContent>
            <FormControl>
              <InputLabel id="session-select-label">Latest sessions</InputLabel>
              <Select
                labelId="session-select-label"
                id="session-select"
                value={this.state.session}
                onChange={(e) => {
                  const sessionIndex = e.target.value as number;
                  this.setState({ session: sessionIndex });
                  if (sessionIndex === -1) {
                    this.setState({ graph_data: [] });
                  } else {
                    this.sessionSelected(this.state.sessions[sessionIndex]);
                  }
                }}
              >
                <MenuItem key={-1} value={-1} disabled>No session selected</MenuItem>
                {this.state.sessions.map((s, index) => (
                  <MenuItem key={index} value={index}>{s.vessel_name} &nbsp;<small style={{ color: 'grey' }}>({s.start_time.toLocaleString()} to {s.end_time.toLocaleString()})</small></MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
        {(this.state.graph_data.length !== 0 || this.state.alarm_data.length !== 0) && (
          <div>
            <br />
            <Card>
              <CardContent>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart syncId="anyId" data={this.state.graph_data} margin={{ top: 20, right: 20, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" tickFormatter={tickFormatter} />
                    <Tooltip labelFormatter={labelFormatter} />
                    <Line type="monotone" name="Left distance" dataKey="distance_left" stroke="red" dot={false} />
                    <Line type="monotone" name="Right distance" dataKey="distance_right" stroke="green" dot={false} />

                    <Line type="monotone" name="Left Speed" dataKey="speed_left" stroke="red" dot={false} />
                    <Line type="monotone" name="Right Speed" dataKey="speed_right" stroke="green" dot={false} />

                    <Line type="monotone" name="Angle" dataKey="angle" stroke="blue" dot={false} />

                    <Brush
                      dataKey="timestamp"
                      height={40}
                      y={260}
                      startIndex={0}
                      endIndex={Math.ceil(this.state.graph_data.length / 10)}
                      tickFormatter={tickFormatter}
                    >
                      <LineChart>
                        <Line type="monotone" name="Left distance" dataKey="distance_left" stroke="red" dot={false} />
                        <Line type="monotone" name="Right distance" dataKey="distance_right" stroke="green" dot={false} />

                        <Line type="monotone" name="Left Speed" dataKey="speed_left" stroke="red" dot={false} />
                        <Line type="monotone" name="Right Speed" dataKey="speed_right" stroke="green" dot={false} />

                        <Line type="monotone" name="Angle" dataKey="angle" stroke="blue" dot={false} />
                      </LineChart>
                    </Brush>
                  </LineChart>
                </ResponsiveContainer>
                <br />
                <hr />
                <br />
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart syncId="anyId" data={this.state.graph_data}
                    margin={{ top: 20, right: 20, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" tickFormatter={tickFormatter} />
                    <YAxis label={{ value: 'Distance', angle: -90, position: 'insideLeft' }} unit="m" yAxisId="left" />
                    <Tooltip labelFormatter={labelFormatter} />
                    <Legend />
                    <Line type="monotone" name="Left distance" dataKey="distance_left" yAxisId="left" stroke="red" dot={false} />
                    <Line type="monotone" name="Right distance" dataKey="distance_right" yAxisId="left" stroke="green" dot={false} />
                  </LineChart>
                </ResponsiveContainer>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart syncId="anyId" data={this.state.graph_data}
                    margin={{ top: 20, right: 20, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" tickFormatter={tickFormatter} />
                    <YAxis label={{ value: 'Speed', angle: -90, position: 'insideLeft' }} unit="cm/s" />
                    <Tooltip labelFormatter={labelFormatter} />
                    <Legend />
                    <ReferenceLine y={2} stroke="red" strokeDasharray="5 5" />
                    <ReferenceLine y={1.5} stroke="orange" strokeDasharray="5 5" />
                    <Line type="monotone" name="Left Speed" dataKey="speed_left" stroke="red" dot={false} />
                    <Line type="monotone" name="Right Speed" dataKey="speed_right" stroke="green" dot={false} />
                  </LineChart>
                </ResponsiveContainer>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart syncId="anyId" data={this.state.graph_data}
                    margin={{ top: 5, right: 20, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" tickFormatter={tickFormatter} />
                    <YAxis label={{ value: 'Degrees', angle: -90, position: 'insideLeft' }} unit="°" />
                    <Tooltip labelFormatter={labelFormatter} />
                    <Legend />
                    <Line type="monotone" name="Angle" dataKey="angle" stroke="blue" dot={false} />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
            <br />
            <Button variant="contained" href={this.getSessionDownloadLink(this.state.sessions[this.state.session])} target="_blank">Download (CSV-format)</Button>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="docking table">
                <TableHead>
                  <TableRow>
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Distance Left</TableCell>
                    <TableCell>Distance Right</TableCell>
                    <TableCell>Speed Left</TableCell>
                    <TableCell>Speed Right</TableCell>
                    <TableCell>Angle</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.graph_data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">{labelFormatter(data.timestamp)}</TableCell>
                      <TableCell>{data.distance_left}</TableCell>
                      <TableCell>{data.distance_right}</TableCell>
                      <TableCell style={{color: this.getAlarmColor(data.speed_left, data.warning_zone?.speed_warning, data.warning_zone?.speed_alarm)}}>{data.speed_left} {this.getAlarmIcon(data.speed_left, data.warning_zone?.speed_warning, data.warning_zone?.speed_alarm)}</TableCell>
                      <TableCell style={{color: this.getAlarmColor(data.speed_right, data.warning_zone?.speed_warning, data.warning_zone?.speed_alarm)}}>{data.speed_right} {this.getAlarmIcon(data.speed_right, data.warning_zone?.speed_warning, data.warning_zone?.speed_alarm)}</TableCell>
                      <TableCell style={{color: this.getAlarmColor(data.angle, data.warning_zone?.angle_warning, data.warning_zone?.angle_alarm)}}>{data.angle} {this.getAlarmIcon(data.angle, data.warning_zone?.angle_warning, data.warning_zone?.angle_alarm)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br/>
            <Button variant="contained" href={this.getSessionAlarmsDownloadLink(this.state.sessions[this.state.session])} target="_blank">Download (CSV-format)</Button>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="alarm table">
                <TableHead>
                  <TableRow>
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Alarm State</TableCell>
                    <TableCell>Text</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.alarm_data.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">{labelFormatter(data.timestamp)}</TableCell>
                      <TableCell>{data.state === AlarmState.ALERT ? 'Alert' : 'Warning'}</TableCell>
                      <TableCell>{data.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
}

export default DownloadSession;
