import React from 'react';
import { Stage, Layer, Rect, Text, Line, Image, Circle } from 'react-konva';
import useImage from 'use-image';
import WarningZones, { WarningZone, AlarmMode } from './WarningZones';

type DockingDisplayProps = {
  vesselName: string,
  rawAngle: number,
  portSide: boolean,
  leftDistance: number,
  rightDistance: number,
  leftSpeed: number,
  rightSpeed: number,

  stageWidth: number,
  stageHeight: number,

  warningZone: WarningZone | null,

  replayMode?: boolean,
};

type Point = {
  x: number,
  y: number,
};

const unknownSymbol = '—';

function getColor(value: number, warningLimit: number | undefined, alertLimit: number | undefined): string {
  switch (WarningZones.getAlarmMode(value, warningLimit, alertLimit)) {
    case AlarmMode.UNKNOWN: return '#777';
    case AlarmMode.NO_ALARM: return 'black';
    case AlarmMode.WARNING: return 'orange';
    case AlarmMode.ALERT: return 'red';
  }
}

const DockingDisplay = ({ vesselName, rawAngle, portSide, leftDistance, rightDistance, leftSpeed, rightSpeed, stageWidth, stageHeight, warningZone, replayMode }: DockingDisplayProps) => {
  const getFormatted = (value: number) => {
    if (isNaN(value) || value === null) return unknownSymbol;
    const decimals = (Math.abs(value) < 10 ? 1 : 0);
    return value.toFixed(decimals);
  };
  const angle = ((isNaN(rawAngle) || rawAngle == null ? 0 : rawAngle) * (portSide ? 1 : -1)) % 360;
  const reversed = (angle > 90 && angle < 270);
  const rotWidth = -130; 
  const rotWidth2 = 150; 
  const rotHeight = (reversed ? -100 : 100);
  const pointCenterX = stageWidth / 2 - 5;
  const pointCenterY = stageHeight - 275;
  const rad = angle * Math.PI / 180;
  const point1: Point = {
    x: pointCenterX + rotWidth * Math.cos(rad) - rotHeight * Math.sin(rad),
    y: pointCenterY + rotHeight * Math.cos(rad) + rotWidth * Math.sin(rad),
  };
  const point2: Point = {
    x: pointCenterX + rotWidth2 * Math.cos(rad) - rotHeight * Math.sin(rad),
    y: pointCenterY + rotHeight * Math.cos(rad) + rotWidth2 * Math.sin(rad),
  };
  const line1 = (reversed ? point2 : point1);
  const line2 = (reversed ? point1 : point2);

  const angleColor = getColor(angle, warningZone?.angle_warning, warningZone?.angle_alarm);
  const leftSpeedColor = getColor(leftSpeed, warningZone?.speed_warning, warningZone?.speed_alarm);
  const rightSpeedColor = getColor(rightSpeed, warningZone?.speed_warning, warningZone?.speed_alarm);

  
  return (
    <Stage width={stageWidth} height={450}>
      <Layer>
        <Rect
          x={1}
          y={1}
          stroke="#777"
          strokeWidth={1}
          fill={replayMode ? undefined : 'white'}
          width={stageWidth - 2}
          height={stageHeight - 2}
          fillPatternImage={useImage('replay-background.png')[0]}
          fillPatternRepeat="repeat"
          cornerRadius={6}
          preventDefault={false}
        />
        <Image
          x={stageWidth / 2 - 5}
          y={stageHeight - 280}
          image={useImage('vessel.png')[0]}
          offsetX={209 / 2} // 209x591 are the image dimensions
          offsetY={591 / 2}

          rotation={angle - 90}
          preventDefault={false}
        />
        <Circle
          x={point1.x}
          y={point1.y}
          radius={8}
          fill="#777"
          preventDefault={false}
        />
        <Circle
          x={point2.x}
          y={point2.y}
          radius={8}
          fill="#777"
          preventDefault={false}
        />
        <Text
          offsetX={200}
          offsetY={20}
          x={stageWidth / 2}
          y={stageHeight - 280}
          fill="#eee"
          text={vesselName || unknownSymbol}
          align="center"
          verticalAlign="middle"
          fontSize={40}
          width={400}
          height={40}
          rotation={reversed ? angle - 180 : angle}
          preventDefault={false}
        />
        <Line
          x={line1.x}
          y={stageHeight - 75}
          points={[0, 0, 0, line1.y - (stageHeight - 75)]}
          stroke="#777"
          strokeWidth={3}
          dash={[10, 5]}
          preventDefault={false}
        />
        <Text
          x={line1.x + 15}
          y={stageHeight - 120}
          text={getFormatted(leftSpeed) + ' cm/s'}
          fill={leftSpeedColor}
          align="left"
          verticalAlign="middle"
          fontSize={22}
          preventDefault={false}
        />
        <Rect
          x={line1.x - 50}
          y={stageHeight - 70}
          stroke="#777"
          strokeWidth={2}
          fill="#eee"
          width={100}
          height={40}
          // shadowColor="black"
          // shadowBlur={5}
          // shadowOffsetX={3}
          // shadowOffsetY={3}
          // shadowOpacity={0.2}
          cornerRadius={6}
          preventDefault={false}
        />
        <Text
          x={line1.x - 50}
          y={stageHeight - 60}
          text={getFormatted(leftDistance) + ' m'}
          align="center"
          verticalAlign="middle"
          fontSize={22}
          width={100}
          preventDefault={false}
        />


        <Text
          x={(point1.x + point2.x) / 2 - 50}
          y={stageHeight - 60}
          text={getFormatted(rawAngle) + '°'}
          fill={angleColor}
          align="center"
          verticalAlign="middle"
          fontSize={22}
          width={100}
          preventDefault={false}
        />


        <Line
          x={line2.x}
          y={stageHeight - 75}
          points={[0, 0, 0, line2.y - (stageHeight - 75)]}
          stroke="#777"
          strokeWidth={3}
          dash={[10, 5]}
          preventDefault={false}
        />
        <Text
          x={line2.x + 15}
          y={stageHeight - 120}
          text={getFormatted(rightSpeed) + ' cm/s'}
          fill={rightSpeedColor}
          align="left"
          verticalAlign="middle"
          fontSize={22}
          preventDefault={false}
        />
        <Rect
          x={line2.x - 50}
          y={stageHeight - 70}
          stroke="#777"
          strokeWidth={2}
          fill="#eee"
          width={100}
          height={40}
          // shadowColor="black"
          // shadowBlur={5}
          // shadowOffsetX={3}
          // shadowOffsetY={3}
          // shadowOpacity={0.2}
          cornerRadius={6}
          preventDefault={false}
        />
        <Text
          x={line2.x - 50}
          y={stageHeight - 60}
          text={getFormatted(rightDistance) + ' m'}
          align="center"
          verticalAlign="middle"
          fontSize={22}
          width={100}
          preventDefault={false}
        />
      </Layer>
    </Stage>
  );
};

export default DockingDisplay;
