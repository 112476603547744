
export type WarningZone = {
  angle_alarm: number,
  angle_warning: number,
  speed_alarm: number,
  speed_warning: number,
  speed_average_measurements: number,
  angle_average_measurements: number,
  distance_average_measurements: number,
  end_distance: number,
}

export enum AlarmMode {
  UNKNOWN,
  NO_ALARM,
  WARNING,
  ALERT,
}

class WarningZones {
  static fromJSON(json: any): WarningZone[] {
    if (!json || !json.Zones) return [];

    // Convert MQTT JSON protocol to our WarningZone object format.
    const warningZones: WarningZone[] = json.Zones.map((z: any) => {
      return {
        angle_alarm: z['Angle alarm'],
        angle_warning: z['Angle warning'],
        speed_alarm: z['Speed alarm'],
        speed_warning: z['Speed warning'],
        speed_average_measurements: z['Speed average measurements'],
        angle_average_measurements: z['Angle average measurements'],
        distance_average_measurements: z['Distance average measurements'],
        end_distance: z['End distance'],
      };
    });

    // sort from farthest to closest zone
    const sortedWarningZones = warningZones.sort((a, b) => b.end_distance - a.end_distance);

    // convert speed values from m/s to cm/s
    const normalizedWarningZones = sortedWarningZones.map((s) => {
      return {
        ...s,
        speed_alarm: s.speed_alarm * 100,
        speed_warning: s.speed_warning * 100,
      };
    });
    return normalizedWarningZones;
  }

  static getWarningZone(zones: WarningZone[], maxDistance: number): WarningZone | null {
    let warningZone = null;
    for (const zone of zones) {
      if (maxDistance > zone.end_distance) break; // the list is sorted from farthest zone to closest zone
      warningZone = zone;
    }
    return warningZone;
  }

  static getAlarmMode(value: number, warningLimit: number | undefined, alertLimit: number | undefined): AlarmMode {
    if (!alertLimit && !warningLimit) {
      return AlarmMode.UNKNOWN;
    }
    if (alertLimit && value >= alertLimit) {
      return AlarmMode.ALERT;
    } else if (warningLimit && value >= warningLimit) {
      return AlarmMode.WARNING;
    }
    return AlarmMode.NO_ALARM;
  }
}

export default WarningZones;
