import React from 'react';
import ResizeObserver from 'react-resize-observer';
import DockingDisplay from './DockingDisplay';
import mqttClient, { ConnectFunc } from './MqttClient';
import WarningZones, { WarningZone } from './WarningZones';

const MQTTPattern = require('mqtt-pattern');

enum DockingOrientation {
  STARBOARD_SIDE_ALONG = 0,
  PORT_SIDE_ALONG,
}

type DockingWidgetProps = {
  replayMode: boolean
};

type DockingWidgetState = {
  vesselName: string,
  rawAngle: number,
  portSide: boolean,
  leftDistance: number,
  rightDistance: number,
  leftSpeed: number,
  rightSpeed: number,
  stageWidth: number,
  stageHeight: number,
  warningZones: WarningZone[],
  currentWarningZone: WarningZone | null,
}

let connectHandle: ConnectFunc | null;

class DockingWidget extends React.Component<DockingWidgetProps, DockingWidgetState> {
  container: HTMLElement | null = null;
  subscriptionSession = '/+/TerminalSession';
  subscriptionTerminalData = '/+/TerminalData';
  subscriptionZones = '/+/Terminal zones';
  subscriptions = [this.subscriptionSession, this.subscriptionTerminalData, this.subscriptionZones];

  constructor(props: DockingWidgetProps) {
    super(props);
    this.state = {
      vesselName: '',
      rawAngle: NaN,
      portSide: true,
      leftDistance: NaN,
      rightDistance: NaN,
      leftSpeed: NaN,
      rightSpeed: NaN,
      stageWidth: window.innerWidth,
      stageHeight: window.innerHeight,
      warningZones: [],
      currentWarningZone: null,
    };
  }

  handleMessage(topic: string, data: any) {   
    if (MQTTPattern.matches(mqttClient.lmsTopic + this.subscriptionSession, topic)) {
      this.setState({
        vesselName: data.VesselName,
        portSide: (data.DockingOrientation === DockingOrientation.PORT_SIDE_ALONG),
      });
    } else if (MQTTPattern.matches(mqttClient.lmsTopic + this.subscriptionTerminalData, topic)) {
      const warningZone = WarningZones.getWarningZone(this.state.warningZones, Math.max(data.DistanceLeft, data.DistanceRight));
      this.setState({
        rawAngle: data.Angle % 360,
        leftDistance: data.DistanceLeft,
        rightDistance: data.DistanceRight,
        leftSpeed: data.SpeedLeft,
        rightSpeed: data.SpeedRight,
        currentWarningZone: warningZone,
      });
    } else if (MQTTPattern.matches(mqttClient.lmsTopic + this.subscriptionZones, topic)) {
      this.setState({
        warningZones: WarningZones.fromJSON(data),
      });
    }
  }

  parseMessage = (topic: string, data: any) => {
    const isReplayMode = this.props.replayMode;
    if (isReplayMode && MQTTPattern.matches(`smartdas/server/stream/data/${mqttClient.clientId}`, topic)) {
      this.handleMessage(data.topic, JSON.parse(data.message)); // use topic and message from replayed data
    } else if (!isReplayMode) {
      this.handleMessage(topic, data);
    }
  }

  componentDidMount() {
    connectHandle = mqttClient.onConnect(() => {
      for (const s of this.subscriptions) {
        mqttClient.subscribe(mqttClient.lmsTopic + s);
        mqttClient.listen(mqttClient.lmsTopic + s, this.parseMessage);
      }

      mqttClient.listen(`smartdas/server/stream/data/${mqttClient.clientId}`, this.parseMessage);
    });
  }

  componentWillUnmount() {
    if (connectHandle) mqttClient.removeOnConnect(connectHandle);

    for (const s of this.subscriptions) {
      mqttClient.unsubscribe(mqttClient.lmsTopic + s);
      mqttClient.unlisten(mqttClient.lmsTopic + s);
    }

    mqttClient.unlisten(`smartdas/server/stream/data/${mqttClient.clientId}`);
  }

  render() {
    return (
      <div ref={node => {
        this.container = node;
      }}>
        <DockingDisplay
          vesselName={this.state.vesselName}
          rawAngle={this.state.rawAngle}
          portSide={this.state.portSide}
          leftDistance={this.state.leftDistance}
          rightDistance={this.state.rightDistance}
          leftSpeed={this.state.leftSpeed}
          rightSpeed={this.state.rightSpeed}
          
          stageWidth={this.state.stageWidth}
          stageHeight={this.state.stageHeight}

          warningZone={this.state.currentWarningZone}

          replayMode={this.props.replayMode}
        />
        <ResizeObserver
          onResize={(rect) => {
            // console.log('Resized. New bounds:', rect.width, 'x', rect.height);
            this.setState({
              stageWidth: rect.width,
              stageHeight: rect.height,
            });
          }}
        />
      </div>
    );
  }
}

export default DockingWidget;
