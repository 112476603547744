import mqttClient from './MqttClient';

export enum AlarmState {
  WARNING = 'warning nack',
  ALERT = 'alarm nack',
}

export type AlarmData = {
  timestamp: Date,
  message: string,
  state: AlarmState,
}

class AlarmManager {
  private alarms: AlarmData[] = [];
  private listeners: ((alarms: AlarmData[]) => void)[] = [];

  constructor() {
    mqttClient.onConnect(() => {
      mqttClient.subscribe(`${mqttClient.lmsTopic}/alarms`);
      mqttClient.listen(`${mqttClient.lmsTopic}/alarms`, (topic, data) => {
          if (!data) return;
          const timestamp = new Date();
          const newAlarms = data.alarms.map((a: any) => { 
            return {
              ...a,
              timestamp: timestamp,
            };
          });
          newAlarms.forEach((a: AlarmData) => this.alarms.push(a)); 
          this.listeners.forEach((l) => l(newAlarms));
      });
    });    
  }

  listenForAlarms(cb: ((alarms: AlarmData[]) => void)) {
    this.listeners.push(cb);
  }

  getAlarms() {
    return this.alarms.slice(0); // return shallow copy of array
  }
}

export default AlarmManager;
