import React, { createContext, useContext, useState } from 'react';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Docking from './Docking';
import Replay from './Replay';
import Sessions from './Sessions';
import TopBar from './TopBar';
import AlarmManager from './AlarmManager';
import Login from './Login';
import Alarms from './Alarms';

import PrivateRoute from './PrivateRoute';
import Profile from './Profile';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F2F2F2',
    },
    primary: {
      // light: '#757ce8',
      main: '#393939',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    secondary: {
      // light: '#ff7961',
      main: '#998242',
      // dark: '#ba000d',
      contrastText: '#fff',
    },
  },
});

// const Docking = lazy(() => import('./Docking'));
// const Replay = lazy(() => import('./Replay'));
// const Sessions = lazy(() => import('./Sessions'));
// const Profile = lazy(() => import('./Profile'));

// const loggedInUser = localStorage.getItem('user');

const routing = (
  // <Suspense fallback={<div>Loading...</div>}>
  // !loggedInUser ? <Route path="/login" component={Login} /> :
  <Switch>
    <PrivateRoute path={['/', '/docking']} exact component={Docking} />
    <PrivateRoute path="/replay" component={Replay} />
    <PrivateRoute path="/sessions" component={Sessions} />
    <PrivateRoute path="/profile" component={Profile} />
    <Route path="/login" exact component={Login} />
    <Route path="/login/:param" exact component={Login} />
  </Switch>
  // </Suspense>
);

export type User = {
  email: string,
  mqttBrokerHost: string,
  mqttBrokerUser: string,
  mqttBrokerPassword: string,
  siteId: string,
  lmsId: string,
}

type SettingsContextData = {
  // alarms
  alarmsAuditory: boolean,
  setAlarmsAuditory: (enabled: boolean) => void,
  alarmsSpoken: boolean,
  setAlarmsSpoken: (enabled: boolean) => void,
  alarmsPopup: boolean,
  setAlarmsPopup: (enabled: boolean) => void,
  alarmsBrowser: boolean,
  setAlarmsBrowser: (enabled: boolean) => void,

  user: User | null,
  setUser: (user: User | null) => void,
  
  // other (eg. audio alarms)
}

export const SettingsContext = createContext<SettingsContextData>({
  alarmsAuditory: true,
  setAlarmsAuditory: () => {},
  alarmsSpoken: true,
  setAlarmsSpoken: () => {},
  alarmsPopup: true,
  setAlarmsPopup: () => {},
  alarmsBrowser: true,
  setAlarmsBrowser: () => {},

  user: null,
  setUser: () => {},
});

export const SettingsProvider = ({ children }: any): JSX.Element => {
  const [alarmsAuditory, setAlarmsAuditory] = useState(false);
  const [alarmsSpoken, setAlarmsSpoken]     = useState(false);
  const [alarmsPopup, setAlarmsPopup]       = useState(false);
  const [alarmsBrowser, setAlarmsBrowser]   = useState(false);
  const [user, setUser]                     = useState<User | null>(null);

  const providerValue : SettingsContextData = {
    alarmsAuditory,
    setAlarmsAuditory,
    alarmsSpoken,
    setAlarmsSpoken,
    alarmsPopup,
    setAlarmsPopup,
    alarmsBrowser,
    setAlarmsBrowser,

    user,
    setUser,
  };

  return (
    <SettingsContext.Provider value={providerValue}>
        {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);

const alarmManager = new AlarmManager();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider
          maxSnack={10}
          preventDuplicate={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Router>
            <SettingsProvider>
              <TopBar alarmManager={alarmManager} />
              <div style={{ padding: 50 /* see https://material-ui.com/components/grid/#negative-margin */ }}>
                {routing}
              </div>
              <Alarms />
            </SettingsProvider>
          </Router>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
