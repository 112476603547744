import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Checkbox, FormControlLabel, Grid, Link } from '@material-ui/core';
import { User, useSettings } from './App';
import { Redirect, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import mqttClient from './MqttClient';
import configuration from './Configuration';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let loggedInUser = localStorage.getItem('user');

export default function Login() {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [staySignedIn, setStaySignedIn] = useState(true);
  const {
    setAlarmsAuditory,
    setAlarmsSpoken,
    setAlarmsPopup,
    setAlarmsBrowser,
    user, setUser
  } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loggedInUser && !user) {
      try {
        const userData: User | null = JSON.parse(loggedInUser);
        if (userData && userData.email) {
          setupUser(userData);
          
          enqueueSnackbar(`Welcome back, ${userData.email}`, { 
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            variant: 'success',
            key: 'auth-success',
          });
        }
      } catch (e) {
        console.log(`Error parsing stored user data: ${e}`);
      }
      loggedInUser = null;
    }
  });

  const conditionalRedirect = () => user ? <Redirect to={'/docking'} /> : <></>;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(email, password, staySignedIn);
  };
  
  const setupUser = (user: User) => {
    const storedSettings = localStorage.getItem('settings');
    const settingsJSON = storedSettings ? JSON.parse(storedSettings) : {};

    const valueOrDefault = (value: boolean | undefined, defaultValue: boolean) => (value !== undefined ? value : defaultValue);
    
    setAlarmsAuditory(valueOrDefault(settingsJSON.alarmsAuditory, true));
    setAlarmsSpoken(valueOrDefault(settingsJSON.alarmsSpoken, true));
    setAlarmsPopup(valueOrDefault(settingsJSON.alarmsPopup, true));
    setAlarmsBrowser(valueOrDefault(settingsJSON.alarmsBrowser, false));
    
    setUser(user);
    
    mqttClient.setup(user.mqttBrokerHost, user.email, user.mqttBrokerUser, user.mqttBrokerPassword, user.siteId, user.lmsId);
  };

  const login = async (user_email: string, user_password: string, stay_logged_in: boolean = true) => {
    const authData = { email: user_email, password: user_password };
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(authData)
    };
    const authUrl = configuration.auth_server_url;
    let response;
    try {
      response = await fetch(authUrl, requestOptions);
    } catch (e) {
      return enqueueSnackbar(`${e}`, { 
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        variant: 'warning',
        key: 'auth-error',
      });
    }
    if (!response.ok) {
      const message = (response.status === 401 ? 'Incorrect email/password' : `Error connecting to authentication server (code ${response.status})`);
      return enqueueSnackbar(message, { 
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        variant: 'warning',
        key: 'auth-error',
      });
    }

    // set the state of the user
    const userData: User | null = await response.json();
    if (!userData) {
      return enqueueSnackbar('Invalid response from authentication server', { 
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        variant: 'warning',
        key: 'auth-response-error',
      });
    }
    
    setupUser(userData);
    
    // store the user in localStorage
    if (stay_logged_in) localStorage.setItem('user', JSON.stringify(userData));

    enqueueSnackbar(`Welcome, ${userData.email}`, { 
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: 'success',
      key: 'auth-success',
    });
  };
  
  const loginDemo = () => {
    login('demo@safepilot.eu', 'demo', false);
  };

  // Log in immediately as the demo-user if the URL is /login/demo
  const { param } = useParams<Record<string, string | undefined>>();
  if (param === 'demo') {
    loginDemo();
  }

  return (
    <Container component="main" maxWidth="xs">
      {conditionalRedirect()}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox value="stay-signed-in" color="primary" defaultChecked onChange={(e) => setStaySignedIn(e.target.checked)} />
            }
            label="Stay signed in"
            value={true}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Log In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
          <Grid container>
            <Grid item xs>
            {user?.email}
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={loginDemo}>
                Skip and proceed to demo version.
                {/* Demo */}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
