import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSettings } from './App';
import { Redirect } from 'react-router-dom';
import mqttClient from './MqttClient';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Profile() {
  const classes = useStyles();

  const {
    alarmsAuditory, setAlarmsAuditory,
    alarmsSpoken, setAlarmsSpoken,
    alarmsPopup, setAlarmsPopup,
    alarmsBrowser, setAlarmsBrowser,
    user, setUser
  } = useSettings();

  const { enqueueSnackbar } = useSnackbar();

  const saveSettings = () => {
    const settingsJSON = JSON.stringify({ alarmsAuditory, alarmsSpoken, alarmsPopup, alarmsBrowser });
    localStorage.setItem('settings', settingsJSON);
    
    enqueueSnackbar('Settings saved.', { 
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant: 'success',
      key: 'save-success',
      autoHideDuration: 1000,
    });
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    mqttClient.disconnet();
  };
  const conditionalRedirect = () => !user ? <Redirect to={'/'} /> : <></>;

  return (
    <Container component="main" maxWidth="xs">
      {conditionalRedirect()}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Profile
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={user?.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="mqtt-broker-host"
            label="MQTT Broker Host"
            id="mqtt-broker-host"
            value={user?.mqttBrokerHost}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="site-id"
            label="Site Id"
            id="site-id"
            value={user?.siteId}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="lms-id"
            label="LMS Id"
            id="lms-id"
            value={user?.lmsId}
          />
          <br/>
          <br/>
          <FormControl component="fieldset">
            <FormLabel component="legend">Alarm Notifications</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={alarmsAuditory} onChange={(e) => setAlarmsAuditory(e.target.checked)} name="auditoryAlarmNotifications" />}
                label="Auditory notifications"
              />
              <FormControlLabel
                control={<Checkbox checked={alarmsSpoken} onChange={(e) => setAlarmsSpoken(e.target.checked)} name="spokenAlarmNotifications" />}
                label="Spoken notifications"
              />
              <FormControlLabel
                control={<Checkbox checked={alarmsPopup} onChange={(e) => setAlarmsPopup(e.target.checked)} name="popupAlarmNofitications" />}
                label="Pop-up notifications"
              />
              <FormControlLabel
                control={<Checkbox checked={alarmsBrowser} onChange={(e) => setAlarmsBrowser(e.target.checked)} name="browserAlarmNofitications" />}
                label="Browser notifications"
              />
            </FormGroup>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={saveSettings}
          >
            Save settings
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={logout}
          >
            Log out
          </Button>
        </form>
      </div>
    </Container>
  );
}
