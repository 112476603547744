import Mqtt from 'mqtt';
import MQTTPattern from 'mqtt-pattern';
import { v4 as uuidv4 } from 'uuid';

let client: Mqtt.Client;

export type ConnectFunc = () => void;
let onConnectFuncs: ConnectFunc[] = [];
function onConnect(func: ConnectFunc) {
  onConnectFuncs.push(func);
  if (client?.connected) {
    func();
  }
  return func;
}

function removeOnConnect(func: ConnectFunc) {
  onConnectFuncs = onConnectFuncs.filter(f => f !== func);
}

const subscriptions = new Set<string>(); // TODO: Should be a map of <widget-id, subscription> (or vice versa) -- see the SafePilot MQTT implementation
function subscribe(subscription: string) {
  if (client && !subscriptions.has(subscription)) {
    client.subscribe(subscription);
  }
  subscriptions.add(subscription);
}

// TODO: Widgets should be able to unmount without unsubscribing other widgets
function unsubscribe(subscription: string) {
  const didDelete = subscriptions.delete(subscription);
  if (!didDelete) {
    return;
  }
  if (client) client.unsubscribe(subscription);
}

type CallbackType = (topic: string, data: any) => void;
const listeners = new Map<string, CallbackType[]>();
function listen(subscription: string, callback: CallbackType) {
  if (!listeners.has(subscription)) {
    listeners.set(subscription, [callback]);
  } else {
    listeners.get(subscription)?.push(callback);
  }
}

function unlisten(subscription: string) {
  listeners.delete(subscription);
}

function publish(topic: string, message: string) {
  client?.publish(topic, message, (err) => {
    if (err) console.log(err);
  });
}

function disconnect() {
  client.removeAllListeners(); // Just in case.
  client.end(true);
}

function setup(host: string, userId: string, brokerUsername: string, brokerPassword: string, siteId: string, lmsId: string) {
  mqttClient.siteId = siteId;
  mqttClient.lmsId = lmsId;
  mqttClient.lmsTopic = `site/${siteId}/lms/${lmsId}`;
  mqttClient.clientId = userId + '_' + uuidv4(); // Generate a unique client ID.

  client = Mqtt.connect(host, {
    clientId: mqttClient.clientId,
    username: brokerUsername,
    password: brokerPassword,
    clean: true,
    will: { // Notify that the clientId has disconnected. This is, among other things, used by the replayer to stop the stream.
      topic: `clients/${mqttClient.clientId}/status`,
      payload: '', // An empty string deletes the persisted message.
      qos: 2,
      retain: true, // Retain this until the client leaves, to keep the information available for listeners who collect afterwards
    }
  });

  client.on('connect', function (_connack: any) {
    console.log('*** EVENT: connect ***');
    publish(`clients/${mqttClient.clientId}/status`, 'online');

    for (const func of onConnectFuncs) {
      func();
    }

    for (const subscription of subscriptions) {
      client?.subscribe(subscription);
    }
  });
  client.on('reconnect', function () {
    console.log('*** EVENT: reconnect ***');
  });
  client.on('close', function () {
    console.log('*** EVENT: close ***');
  });
  client.on('disconnect', function (_packet: any) {
    console.log('*** EVENT: disconnect ***');
  });
  client.on('offline', function () {
    console.log('*** EVENT: offline ***');
  });
  client.on('error', function (_error) {
    console.log('*** EVENT: error ***');
  });
  client.on('end', function () {
    console.log('*** EVENT: end ***');
  });
  // client.on('packetreceive', function(packet) {
  //   console.log('*** EVENT: packetreceive ***');
  //   console.log(packet);
  // });
  // client.on('packetsend', function(packet) {
  //   console.log('*** EVENT: packetsend ***');
  //   console.log(packet);
  // });
  client.on('message', function (topic, message) {
    // console.log('*** EVENT: message ***');
    // console.log(topic + ': ' + message);
    listeners.forEach(function (callbacks, subscription) {
      if (MQTTPattern.exec(subscription, topic)) {
        const data = JSON.parse(message.toString());
        callbacks.forEach(callback => callback(topic, data));
      }
    });
  });
}

const mqttClient = {
  clientId: '???',
  lmsTopic: '???',
  siteId: '???',
  lmsId: '???',
  setup: setup,
  onConnect: onConnect,
  removeOnConnect: removeOnConnect,
  disconnet: disconnect,
  subscribe: subscribe,
  unsubscribe: unsubscribe,
  listen: listen,
  unlisten: unlisten,
  publish: publish,
};

export default mqttClient;
