// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import  React from  'react';
import { Route, Redirect } from  'react-router-dom';
import { useSettings } from './App';

const PrivateRoute = (props: { component: React.FC, path: string | string[], exact?: boolean }) => {
    const { user } = useSettings();
    const condition = !!user; //performValidationHere();
    return condition ? <Route path={props.path} component={props.component} exact={props.exact} /> : <Redirect to="/login" />;
};
export default PrivateRoute;
