import React from 'react';
import { Card, CardContent, Button, Typography, } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import mqttClient, { ConnectFunc } from './MqttClient';

type ReplayerProps = {

};

type ReplayerState = {
  replaying: boolean,
  timestamp: Date | null,
  replayDate: Date,
};

let connectHandle: ConnectFunc | null;

class Replayer extends React.Component<ReplayerProps, ReplayerState> {
  constructor(props: ReplayerProps) {
    super(props);
    this.state = {
      replaying: false,
      timestamp: null,
      replayDate: new Date(),
    };
  }

  startStream = (from: Date) => {
    if (this.state.replaying) throw new Error('already replaying!');
    
    const data = {
      from: from,
      responseTopic: `smartdas/server/stream/data/${mqttClient.clientId}`,
      clientId: mqttClient.clientId
    };
    mqttClient.subscribe(`smartdas/server/stream/data/${mqttClient.clientId}`);
    mqttClient.publish('smartdas/server/stream/start', JSON.stringify(data));
    this.setState({
      replaying: true
    });
  };
  
  stopStream = () => {
    if (!this.state.replaying) throw new Error('not replaying!');

    const data = {
      clientId: mqttClient.clientId
    };
    this.setState({
      replaying: false,
      timestamp: null,
    });
    mqttClient.unsubscribe(`smartdas/server/stream/data/${mqttClient.clientId}`);
    mqttClient.publish('smartdas/server/stream/stop', JSON.stringify(data));
  };

  componentDidMount() {
    connectHandle = mqttClient.onConnect(() => {
      mqttClient.listen(`smartdas/server/stream/data/${mqttClient.clientId}`, (topic, data) => {
        this.setState({
          timestamp: new Date(data.timestamp)
        });
      });
    });
  }

  componentWillUnmount() {
    if (connectHandle) mqttClient.removeOnConnect(connectHandle);
    if (this.state.replaying) {
      this.stopStream();
    }
    mqttClient.unlisten(`smartdas/server/stream/data/${mqttClient.clientId}`);
  }
  
  render() {
    return (
      <Card>
        <CardContent>
          <DateTimePicker
            autoOk
            ampm={false}
            disableFuture
            showTodayButton
            label="Replay from"
            value={this.state.replayDate}
            onChange={(date) => this.setState({ replayDate: (date ? date.toDate() : new Date()) })}
          />
          <Button variant="contained" onClick={this.state.replaying ? this.stopStream : () => this.startStream(this.state.replayDate)}>{this.state.replaying ? 'Stop' : 'Start'} stream</Button>&nbsp;&nbsp;
          <Typography variant="body1" component="em">{this.state.replaying ? `Replaying: ${this.state.timestamp ? this.state.timestamp.toLocaleString() : 'N/A'}` : 'Not replaying'}</Typography>
        </CardContent>
      </Card>
    );
  }
}

export default Replayer;
