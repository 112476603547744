import React, { Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, Drawer, Divider, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import AlarmManager, { AlarmState } from './AlarmManager';
import { NavLink } from 'react-router-dom';
import { useSettings } from './App';
import configuration from './Configuration';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  smartPortLogo: {
    height: 42,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  smartDasText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  trelleborgLogo: {
    height: 42,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  rightToolbar: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: theme.palette.secondary.contrastText,
  },
  activeLink: {
    margin: theme.spacing(1, 1.5),
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
}));

type TopBarProps = {
  alarmManager: AlarmManager,
}

const TopBar = ({ alarmManager }: TopBarProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { user } = useSettings();

  function appBar(children: ReactNode) {
    return (
      <AppBar position="static" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
        <Toolbar className={classes.toolbar}>
          <img className={classes.smartPortLogo} src="SmartPortLogo.svg" alt="SmartPort" />
          <Typography className={classes.smartDasText} variant="h6" color="inherit" noWrap>
            &nbsp;{configuration.title}
          </Typography>
          {children}
          <div className={classes.rightToolbar}></div>
          {user ?
            (
              <Fragment>
                <Button component={NavLink} to="/profile" className={classes.link} activeClassName={classes.activeLink}>
                  {user.email}
                </Button>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  className={clsx(open && classes.hide)}
                >
                  <NotificationsIcon />
                </IconButton>
              </Fragment>
            ) : <></>
          }
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img className={classes.trelleborgLogo} src="TrelleborgLogo.svg" alt="Trelleborg" />
        </Toolbar>
      </AppBar>
    );
  }

  if (!user) return appBar(<></>);

  return (
    <div>
      {appBar(
        <Fragment>
          <Button component={NavLink} to="/docking" className={classes.link} activeClassName={classes.activeLink}>
            Docking
          </Button>
          <Button component={NavLink} to="/replay" className={classes.link} activeClassName={classes.activeLink}>
            Replay
          </Button>
          <Button component={NavLink} to="/sessions" className={classes.link} activeClassName={classes.activeLink}>
            Sessions
          </Button>
        </Fragment>
      )}
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
        Alarms
      </div>
      <Divider />
      <List>
        {alarmManager.getAlarms().reverse().map((alarm, index) => ( // TODO: This only updates when the drawer is opened/closed
          <ListItem button key={index}>
            <ListItemIcon>{alarm.state === AlarmState.WARNING ? <WarningIcon /> : <ErrorIcon />}</ListItemIcon>
            <ListItemText primary={`${alarm.timestamp.toLocaleTimeString()}: ${alarm.message}`} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  </div>
  );
};

export default TopBar;
